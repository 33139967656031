import React from "react";
import classNames from "classnames";
import { Container, Text, Button } from "@atoms";
import loadable from "@loadable/component";

const LocationMap = loadable(() => import("../molecules/LocationMap"));

const MapBlock = ({ copy, image, data, className: _className }) => {
  return (
    <section className={classNames("relative z-30 py-16", _className)}>
      <Container variant="xl">
        <div className="flex flex-col items-center space-y-8">
          <div className="mx-auto max-w-3xl">
            <Text
              richText
              className="text-center prose-p:text-lg lg:prose-p:text-xl"
            >
              {copy}
            </Text>
          </div>
          <div className="h-full w-full">
            <LocationMap image={image} data={data} />
          </div>
          <div className="flex items-center justify-center">
            <Button to="/impact">Our Impact</Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

MapBlock.defaultProps = {};

export default MapBlock;
